import {AnimatePresence, domAnimation, LazyMotion, m} from 'framer-motion'
import * as React from 'react'

export const TRANSITION_EXIT = 0.6
export const TRANSITION_ENTER = 0.8
export const TRANSITION_DELAY = 0.1

export const PageTransitionWrapper = ({children, initial = true}) => (
  <LazyMotion features={domAnimation} strict>
    <AnimatePresence mode='wait' initial={initial}>
      {children}
    </AnimatePresence>
  </LazyMotion>
)

export const PageTransition = (pageProps) => {
  const isFirstRenderRef = React.useRef(true)

  const className = 'pointer-events-none fixed top-0 left-0 z-50 h-screen w-screen bg-blue-800'

  React.useEffect(() => {
    isFirstRenderRef.current = false
  }, [])

  return (
    <PageTransitionWrapper>
      <div key={pageProps.path}>
        <m.div
          className={className}
          initial={{y: '110%'}}
          exit={{y: '0%'}}
          transition={{
            type: 'tween',
            ease: 'circOut',
            duration: TRANSITION_EXIT,
          }}
        />

        <m.div
          className={className}
          initial={isFirstRenderRef.current ? false : {y: '0%'}}
          animate={{y: '-110%'}}
          transition={{
            delay: TRANSITION_DELAY,
            type: 'tween',
            ease: 'easeOut',
            duration: TRANSITION_ENTER,
          }}
        />
      </div>
    </PageTransitionWrapper>
  )
}
