const IS_DEVELOPMENT = [process.env.NODE_ENV, process.env.GATSBY_ENV].includes('development')

const STORYBLOK_TOKEN = 'g7wShioKSkweHRQk3Z3t0Att'
const DEFAULT_LOCALE = 'en'
const RESOLVE_RELATIONS = [
  'global-component.reference',
  'page.category',
  'pages-list.categories',
  'featured-page.page',
  'blog-post.category',
  'blog-post.author',
  'blog-post.tags',
  'button-video.popup',
  'button-image.popup',
]

const GTM_ID = ''

const getPagePath = (full_slug) => {
  return full_slug === 'index' || full_slug === '/'
    ? '/'
    : `/${full_slug.replace('/index', '').replace(/^\/+/, '').replace(/\/+$/, '')}/`
        .replace('/http', 'http')
        .replace('*/', '*')
        .replace(':splat/', ':splat')
}

const getLayoutUuid = (story) =>
  story?.content?.layout || story?.content?.category?.content?.articlesLayout

const generateBreadcrumb = async ({story, stories, getStory, params}) => {
  if (story) {
    const fullSlug = story.full_slug.replace(/\/(index)?$/, '').split('/')
    const hasParent = fullSlug.length > 1

    if (hasParent) {
      const parentSlug = fullSlug.slice(0, -1).join('/') + '/'

      let parent
      if (stories) {
        const story = stories.find(({full_slug}) =>
          [parentSlug, parentSlug + 'index'].includes(full_slug)
        )
        if (story) {
          parent = {...story, content: JSON.parse(story.content)}
        }
      } else if (getStory && params) {
        parent = await getStory(parentSlug, params).catch(
          async () => await getStory(parentSlug + 'index', params).catch(() => {})
        )
      }

      if (parent && typeof parent.content === 'object') {
        const parentBreadcrumb = await generateBreadcrumb({
          story: parent,
          stories,
          getStory,
          params,
        })

        return [
          ...(parentBreadcrumb || []),
          {
            name: parent.name,
            title: parent.content.title,
            full_slug: parent.full_slug.replace(/(\/)?(index)?$/, '/'),
          },
        ]
      }
    }
  }

  return undefined
}

// eslint-disable-next-line no-warning-comments
// DO NOT TOUCH - Populate by prebuild.js
const LOCALES = ["fr"]
const SITE_NAME = 'Smarcb1 Hope'
const SITE_URL = 'https://smarcb1hope.org'

module.exports = {
  DEFAULT_LOCALE,
  GTM_ID,
  IS_DEVELOPMENT,
  LOCALES,
  RESOLVE_RELATIONS,
  SITE_NAME,
  SITE_URL,
  STORYBLOK_TOKEN,
  utils: {
    generateBreadcrumb,
    getLayoutUuid,
    getPagePath,
  },
}
